import Api from '@/services/api'
import { each, find } from 'lodash'
import Category from '@/models/Category'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    categoryDetails: null
  },

  mutations: {
    setCategoryList(state, category) {
      each(category, category => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new Category(category))
        }
      })
    },

    clearCategoryList(state) {
      state.list = []
    },

    setCategoryListMeta(state, meta) {
      state.listMeta = meta
    },

    setCategoryDetails(state, category) {
      if (category instanceof Category) {
        state.categoryDetails = category
      } else {
        state.categoryDetails = new Category(category)
      }
    },

    clearCategoryDetails(state) {
      state.categoryDetails = null
    }
  },

  actions: {
    async getCategories({ commit }, params) {
      const query = Category.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: params.itemsPerPage ?? 20 }).get()

      commit('setCategoryList', res.data)
      commit('setCategoryListMeta', res.meta)
    },

    async getCategoryDetails({ commit }, id) {
      const { data } = await Api.get(`categories/${id}`)
      commit('setCategoryDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      let body = new FormData()
      body.append('label', formData.label)
      body.append('photo', formData.photo)

      const { data } = await Api.post(
        `categories/${formData.id}?_method=PUT`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      commit('setCategoryDetails', data.data)
    },

    async deleteCategory({ commit }, formData) {
      await Api.delete(`categories/${formData.id}`)
    }
  }
}
