import Api from '@/services/api'
import { each, find } from 'lodash'
import Listing from '@/models/Listing'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    listingDetail: null
  },

  mutations: {
    setListing(state, listing) {
      each(listing, listing => {
        const exist = find(state.list, { id: listing.id })
        if (!exist) {
          state.list.push(new Listing(listing))
        }
      })
    },

    clearListings(state) {
      state.list = []
    },

    setListingMeta(state, meta) {
      state.listMeta = meta
    },

    setListingDetails(state, listing) {
      if (listing instanceof Listing) {
        state.listingDetail = listing
      } else {
        state.listingDetail = new Listing(listing)
      }
    },

    clearCategoryDetails(state) {
      state.listingDetail = null
    }
  },

  actions: {
    async getListing({ commit }, params) {
      const query = Listing.page(params.page || 1).orderBy(params.sort)

      if (params.category_search.length > 0) {
        query.where('categories.id', params.category_search)
      }

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.sortBy.length > 0) {
        query.orderBy(params.sortDesc[0] ? `-is_available` : `is_available`)
      }

      const res = await query
        .params({ perPage: params.itemsPerPage ?? 20 })
        .get()

      commit('setListing', res.data)
      commit('setListingMeta', res.meta)
    },

    async getListingDetails({ commit }, id) {
      const { data } = await Api.get(`listings/${id}`)
      commit('setListingDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      const { data } = await Api.put(`listings/${formData.id}`, formData)
      commit('setListingDetails', data.data)
    },

    async deleteCategory({ commit }, formData) {
      await Api.delete(`listings/${formData.id}`)
    }
  }
}
