import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'
import category from './modules/category'
import listing from './modules/listing'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    report,
    versionControl,
    category,
    listing
  },
  state: {
    controls: {
      showDrawer: true
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    }
  }
})
